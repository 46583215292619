import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { bool, node, string } from "prop-types";
import { useContext } from "react";

import FavouriteButton from "~components/FavouriteButton";
import IconButton from "~components/IconButton";
import SearchButton from "~components/SearchButton";
import { NavigationContext } from "~contexts/NavigationContext";
import { is750ThemeEnabled, useTheme } from "~contexts/ThemeContext";

import classes from "./Header.module.scss";

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === "production";

const Header = ({
  leftContent,
  rightContent,
  subText,
  bottomLine = false,
  hideButtons = false,
  showFavouritesButton,
}) => {
  const { toggleTheme } = useTheme();
  const router = useRouter();
  const { i18n } = useTranslation();
  const { currentDomain } = useContext(NavigationContext);

  const isPartyModeEnabled = is750ThemeEnabled(
    currentDomain,
    i18n.language,
    router.asPath,
  );

  return (
    <header className={classes.header}>
      <div className={classes.contentWrapper}>
        {leftContent}
        <div className={classes.logo}>
          <Link href="/" aria-label="Home">
            <Image
              src="/images/iamsterdam-logo.svg"
              alt="iAmsterdam logo"
              width={135}
              height={18}
            />
          </Link>
          {subText && <div className={classes.logoText}>{subText}</div>}
        </div>
        {rightContent}
        {!hideButtons && (
          <div className={classes.mobile_favourite_button}>
            {isPartyModeEnabled && !isProduction && (
              <IconButton
                onClick={toggleTheme}
                icon="balloon"
                className={classes.theme}
              />
            )}
            {showFavouritesButton && <FavouriteButton />}
            <SearchButton />
          </div>
        )}
      </div>
      {bottomLine && <div className={classes.bottomLine} />}
    </header>
  );
};

Header.propTypes = {
  subText: string,
  leftContent: node,
  rightContent: node,
  bottomLine: bool,
  hideButtons: bool,
  showFavouritesButton: bool,
};

export default Header;
